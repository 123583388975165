import React from "react"

import Layout from "../components/layout"

import ImageMeta from "../components/ImageMeta"
import Seamless from "../components/Seamless"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import TheaterVideo from "@components/Theater/TheaterVideo"
import { Link } from "gatsby"

const WisdomApplicationPage = () => {
  let language = "en"

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title="Apply Here For The Wisdom For Wisdom Program"
          description="Follow the Wisdom For Wisdom steps to apply"
        />
        {/* <Button 
          buttonText={language === "es" ? "ATRÁS" : "BACK"}
          goBack
          /> */}

        <div className="joshua-tree-content program-page-wisdom">
          <div className="columns wisdom-application top-section white-back has-text-centered">
            <div className="column">
              <h1>Wisdom For Wisdom Application</h1>
            </div>
          </div>

          <section className="body-section">
            <div className="columns">
              <div className="column is-6"></div>
              <div className="column">
                <div className="application-wrapper">
                  <Seamless src="/w4wForm.js" />
                </div>
              </div>
              <div className="column is-6"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default WisdomApplicationPage
